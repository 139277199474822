import React from 'react';
import '../styles/Resume.css';

const Resume = () => {
    return (
        <div className='resume-section'>
            <div className='resume-content'>
                <h1>Resume</h1>
            </div>
        </div>
    );
};

export default Resume;
import React from 'react';
import '../styles/Home.css';

const Home = () => {
    return (
        <div className="home-section">
            <div className="home-intro">
                <h1>I'm a Developer</h1>
            </div>
        </div>
    );
};

export default Home;

import React from 'react';
import '../styles/Contact.css';

const Contact = () => {
    return (
        <div className='contact-section'>
            <div className='contact-info'>
                <h1>email:</h1>
                <h1>linkedin:</h1>
            </div>
        </div>
    );
};

export default Contact;